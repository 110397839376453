import CryptoJS from "crypto-js";
// 加密函数
export default {
	encrypt(str: string, KEY: string, IV: string) {
		const key = CryptoJS.enc.Utf8.parse(KEY);
		const iv = CryptoJS.enc.Utf8.parse(IV);
		const encrypted = CryptoJS.AES.encrypt(str, key, {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return encrypted.toString();
	},
	// 解密
	decrypt(str: string, KEY: string, IV: string) {
		const key = CryptoJS.enc.Utf8.parse(KEY);
		const iv = CryptoJS.enc.Utf8.parse(IV);
		const decrypt = CryptoJS.AES.decrypt(str, key, {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return decrypt.toString(CryptoJS.enc.Utf8);
	}
};
