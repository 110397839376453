import AES from "./aes.js";
/*
    name:路由名称
    userId:用户等级+用户ID
    website:当前国家缩写_当前语言缩写_货币名称
    fsPvid:从cookie中获取 _fs_pvid
    ecommProdid:产品ID的数组

    ecommPagetype:
        home，首页
        searchresults，搜索页
        category，分类页
        product，单个商品的页面
        cart，在购物车/下单页
        purchase，结账成功页
        community，社区页面
        other，其他

    ecommTotalvalue:总价
*/
export const homePV = () => {
	const userStore = useUserStore();
	const websiteStore = useWebsiteStore();
	const metaStore = useMeatStore();
	const { isLogin, userInfo, gaLoginString } = storeToRefs(userStore);
	const { iso_code, language, currency } = storeToRefs(websiteStore);
	const { title } = storeToRefs(metaStore);
	const route = useRoute();

	const loginStatus = isLogin.value ? `Login_${gaLoginString.value}` : `Logout_${gaLoginString.value}`;
	const userId =
		isLogin.value && userInfo.value
			? AES.encrypt(
					`${userInfo.value.customers_level}${userInfo.value.customers_id}`,
					`_-yu_xuan_3507-_`,
					`fs_com_phone2016`
				)
			: "";
	const websiteLanguage = `${iso_code.value}_${language.value}_${currency.value}`;
	const fsPvid = useCookie("_fs_pvid");
	const page_path = route.fullPath;
	const page_location = window.location.href;
	const ga_community_str = gaCommunityPramas();
	if (window.dataLayer) {
		// 首页埋点
		window.dataLayer.push({
			event: "PageView",
			loginStatus,
			userId,
			websiteLanguage,
			fsPvid,
			ecommPagetype: "home",
			page_location,
			page_path,
			page_title: title.value,
			"c_site&c_ctype&c_from&c_cat&c_rel": ga_community_str,
			page_url: `${window.location.hostname}${window.location.pathname}`,
			page_referrer: document.referrer || ""
		});
	}
};

export const shoppingPV = (ecommProdid: string, ecommTotalvalue: string, ecommPagetype: string) => {
	const userStore = useUserStore();
	const websiteStore = useWebsiteStore();
	const metaStore = useMeatStore();
	const { isLogin, userInfo, gaLoginString } = storeToRefs(userStore);
	const { iso_code, language, currency } = storeToRefs(websiteStore);
	const { title } = storeToRefs(metaStore);
	const route = useRoute();

	const loginStatus = isLogin.value ? `Login_${gaLoginString.value}` : `Logout_${gaLoginString.value}`;
	const userId =
		isLogin.value && userInfo.value
			? AES.encrypt(
					`${userInfo.value.customers_level}${userInfo.value.customers_id}`,
					`_-yu_xuan_3507-_`,
					`fs_com_phone2016`
				)
			: "";
	const websiteLanguage = `${iso_code.value}_${language.value}_${currency.value}`;
	const fsPvid = useCookie("_fs_pvid");
	const page_path = route.fullPath;
	const page_location = window.location.href;
	const ga_community_str = gaCommunityPramas();
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "PageView",
			loginStatus,
			userId,
			websiteLanguage,
			fsPvid,
			ecommProdid,
			ecommPagetype,
			ecommTotalvalue,
			page_location,
			page_path,
			page_title: title.value,
			"c_site&c_ctype&c_from&c_cat&c_rel": ga_community_str,
			page_url: location.href,
			page_referrer: window.location.href // TODO: 旧代码中的使用场景还不是很理解
		});
	}
};

export const gaCommunityPramas = () => {
	const route = useRoute();
	let ga_community_str = ``;
	let ga_community_time: string | number | null = localStorage.getItem("ga_community_time");
	ga_community_time = ga_community_time ? Number(ga_community_time) : null;

	if (ga_community_time && new Date().getTime() - ga_community_time > 1000 * 60 * 60 * 24 * 60) {
		localStorage.removeItem("ga_community_str");
	}

	if (route.query && route.query.c_site && route.query.c_ctype && route.query.c_from) {
		ga_community_str = `${route.query.c_site}&${route.query.c_ctype}&${route.query.c_from}&${route.query.c_cat || null}&${route.query.c_rel || null}`;

		localStorage.setItem("ga_community_str", ga_community_str);
		localStorage.setItem("ga_community_time", JSON.stringify(new Date().getTime()));
		return `true&${ga_community_str}`;
	}

	if (localStorage.getItem("ga_community_str")) {
		ga_community_str = localStorage.getItem("ga_community_str") || "";
		return `false&${ga_community_str}`;
	}

	return null;
};
